import React, { Component, Fragment }  from 'react';


export default class visitor extends Component {

  state = {
    
  }

  render() {
    return (
      <span> { this.props.visitorname } { this.props.visitorlastname } ; </span>
      // <div className="tile is-child box notification is-success">       
      //       <div>
      //         <span>{ this.props.visitorname }</span>
      //         <span> </span>
      //         <span>{ this.props.visitorlastname }</span>
      //       </div>        
      // </div>
    )
  }
}
