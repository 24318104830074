import React, { Component } from 'react';
import { Redirect } from 'react-router';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Headers from './header';
import Footers from './footer';
import profileICO from "../admin.png";
import companyICO from "../enterprise.png";
import mobileICO from "../calls-contacts.png";
import emailICO from "../mail-new.png";
import idICO from "../privacy-or-confidential.png";
import picICO from "../photos.png";
import laptopICO from "../desktop.png";
import serialICO from "../shopping-checkout.png";
import carICO from "../car.png";
import Modal from 'react-bootstrap/Modal';
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import "./register.css";
import axios from "axios";
import Webcam from 'react-webcam';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const config = require('../config.json');
const videoConstraints = {
    facingMode: 'user'
};

class register extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.handleShowCapture = this.handleShowCapture.bind(this);

        const idLogin = this.props.location.state.id;
        const emailLogin = this.props.location.state.email;

        this.state = {
            show: false,
            directTo: "/registerSave",
            country: cookies.get('DVCountry'),
            showCapture: false,
            screenshot: null,
            imagefield: "",
            showWebcam: "block",
            errMsg_Mobile: "none",
            errMsg_name: "none",
            errMsg_lastname: "none",
            errMsg_company: "none",
            errMsg_email: "none",
            errMsg_Image: "none",
            newVisitor: {
                "id": idLogin,
                "firstname": "",
                "lastname": "",
                "company": "",
                "email": emailLogin,
                "laptop": "",
                "laptopserial": "",
                "mobile": "",
                "vehicle": "",
                "image": "",
                "unqkey": uuidv4()
            }
        };

    }

    screenshot() {
        // access the webcam trough this.refs
        var screenshot = this.refs.webcam.getScreenshot();
        this.setState({ screenshot: screenshot });
        this.setState({ showWebcam: "none" });
        this.setState({ imagefield: "image" });
    }


    handleAddVisitor = async () => {
        // add call to AWS API Gateway add visitor endpoint here
        try {
            const params = {
                "unqkey": this.state.newVisitor.unqkey,
                "id": this.state.newVisitor.id,
                "firstname": this.state.newVisitor.firstname,
                "lastname": this.state.newVisitor.lastname,
                "company": this.state.newVisitor.company,
                "mobile": this.state.newVisitor.mobile,
                "username": localStorage.getItem('username'),
                "usid": localStorage.getItem('usersession')
            };
            const unqkey = this.state.newVisitor.unqkey;
            await axios.post(`${config.api.backendUrl}/visitortemp/${unqkey}`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

            this.setState({ redirect: true }, function () {
                this.setState({ directTo: "/registerSave" });
            });

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }


    onAddVisitorNameChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "firstname": event.target.value } });
    onAddVisitorIdChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "id": event.target.value } });
    onAddVisitorLastnameChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "lastname": event.target.value } });
    onAddVisitorCompanyChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "company": event.target.value } });
    onAddVisitorEmailChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "email": event.target.value } });
    onAddVisitorLaptopChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "laptop": event.target.value } });
    onAddVisitorLaptopserialChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "laptopserial": event.target.value } });
    onAddVisitorMobileChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": event.target.value } });
    onAddVisitorVehicleChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "vehicle": event.target.value } });

    handleClose() {
        this.setState({ show: false });
        this.setState({ showCapture: false });
    }

    handleReject() {
        this.setState({ directTo: "reject" });
        this.setState({ show: false });
        this.setState({ redirect: true });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleShowCapture() {
        this.setState({ showCapture: true });
    }

    componentDidMount = () => {
        this.setState({ screenshot: "" });
        this.setState({ newVisitor: { ...this.state.newVisitor, "laptop": " " } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "laptopserial": " " } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": " " } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "vehicle": " " } });

        this.handleShow();
    }

    handleChange = e => {
        this.form.validateFields(e.target);
    }


    resetFunction() {
        let element = document.getElementById("form-group");
        if (element !== null) {
            element.reset();
        }
    }

    registerSubmit = e => {
        e.preventDefault();

        let formValid = true;

        if (this.state.newVisitor.firstname === '') {
            this.setState({ errMsg_name: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_name: "none" });
        }

        if (this.state.newVisitor.lastname === '') {
            this.setState({ errMsg_lastname: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_lastname: "none" });
        }

        if (this.state.newVisitor.company === '') {
            this.setState({ errMsg_company: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_company: "none" });
        }

        // Frans - Added check only for SA
        if (this.state.country === 'South_Africa') {
            if (this.state.newVisitor.mobile.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
                this.setState({ errMsg_Mobile: "none" });
            } else {
                //this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": "" } });
                formValid = false;
                this.setState({ errMsg_Mobile: "block" });
            }
        }

        if (this.state.screenshot === '') {
            this.setState({ errMsg_Image: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_Image: "none" });
        }

        //this.form.validateFields();

        if (formValid) {
            this.handleAddVisitor();
            const id = this.state.newVisitor.id;
            const name = this.state.newVisitor.firstname;
            const lastname = this.state.newVisitor.lastname;
        }

    }

    handleAddVisitsVisitor = async (id, name, lastname) => {
        const curTime = new Date().toLocaleString();
        // add call to AWS API Gateway add visitor endpoint here
        try {
            const params = {
                "id": this.state.newVisitor.unqkey,
                "visitkey": cookies.get('DVvisitkey'),
                "visitorid": id,
                "visitorname": name,
                "visitorlastname": lastname,
                "username": localStorage.getItem('username'),
                "usid": localStorage.getItem('usersession')
            };

            await axios.post(`${config.api.backendURL}/visitsvisitor/{id}`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.directTo,
                state: {
                    id: this.state.newVisitor.id,
                    email: this.state.newVisitor.email,
                    firstname: this.state.newVisitor.firstname,
                    lastname: this.state.newVisitor.lastname,
                    company: this.state.newVisitor.company,
                    mobile: this.state.newVisitor.mobile,
                    image: this.state.screenshot,
                    unqkey: this.state.newVisitor.unqkey
                }
            }} />;
        }
        return (
            <div>

                <div className="text-center">
                    <h3 style={{ margin: '50px 0px 0px 0px' }}>Your details are required to complete your sign in </h3>
                    <br></br>
                    <FormWithConstraints
                        ref={form => this.form = form}
                        onSubmit={this.registerSubmit}
                        noValidate>
                        <div className="container">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <img src={profileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="name"
                                        id="name"
                                        type="text"
                                        placeholder="First Name *"
                                        className="form-control+matching-text-style"
                                        required
                                        style={{ width: '85%' }}
                                        value={this.state.newVisitor.firstname}
                                        onChange={this.onAddVisitorNameChange}
                                    />
                                    <div style={{ display: this.state.errMsg_name, color: 'red' }}><p>First Name is mandatory, please complete</p></div>
                                </div>
                                <div className="form-group col-md-6">
                                    <img src={profileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="surname"
                                        id="surname"
                                        type="text"
                                        placeholder="Last Name *"
                                        className="form-control+matching-text-style"
                                        required
                                        style={{ width: '85%' }}
                                        value={this.state.newVisitor.lastname}
                                        onChange={this.onAddVisitorLastnameChange}
                                    />
                                    <div style={{ display: this.state.errMsg_lastname, color: 'red' }}><p>Last Name is mandatory, please complete</p></div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <img src={companyICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="Company"
                                        id="Company"
                                        type="text"
                                        placeholder="Company Name *"
                                        className="form-control+matching-text-style"
                                        value={this.state.newVisitor.company}
                                        onChange={this.onAddVisitorCompanyChange}
                                        style={{ width: '85%' }}
                                        required
                                    />
                                    <div style={{ display: this.state.errMsg_company, color: 'red' }}><p>Company name is mandatory, please complete</p></div>
                                </div>
                                <div className="form-group col-md-6">
                                    <img src={mobileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="Mobile"
                                        id="Mobile"
                                        type="text"
                                        placeholder="Contact Number *"
                                        className="form-control+matching-text-style"
                                        value={this.state.newVisitor.mobile}
                                        onChange={this.onAddVisitorMobileChange}
                                        style={{ width: '85%' }}
                                        required
                                    />
                                    <div style={{ display: this.state.errMsg_Mobile, color: 'red' }}><p>Invalid number captured, please complete</p></div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <img src={idICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="Id"
                                        id="Id"
                                        type="text"
                                        placeholder="RSA ID/Passport number"
                                        className="form-control+matching-text-style"
                                        required
                                        style={{ width: '85%' }}
                                        value={this.state.newVisitor.id}
                                        readOnly
                                    />
                                    <FieldFeedbacks for="Id">
                                        <FieldFeedback when="*" />
                                    </FieldFeedbacks>
                                </div>
                                <div className="form-group col-md-6">
                                    <img src={picICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="avatar"
                                        id="avatar"
                                        type="text"
                                        placeholder="Capture Profile Image *"
                                        className="form-control+matching-text-style"
                                        onChange={() => { }}
                                        style={{ width: '85%' }}
                                        onClick={this.handleShowCapture}
                                        value={this.state.imagefield}
                                    />
                                    <div style={{ display: this.state.errMsg_Image, color: 'red' }}><p>Image is mandatory, please complete</p></div>
                                </div>
                            </div>

                            <div className="text-center">
                                <button style={{ margin: '0px 0px 70px 0px' }} onClick={this.resetFunction} className="btn btn-secondary" >Next</button>
                            </div>

                        </div>
                    </FormWithConstraints>



                </div>


                <Modal show={this.state.showCapture}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                    style={{ textAlign: "center" }}>

                    <Modal.Body style={{ textAlign: "center" }}>

                        <Webcam
                            audio={false}
                            ref='webcam'
                            screenshotFormat="image/jpeg"
                            height={"420px"}
                            width={"470px"}
                            facingMode="user"
                            style={{ display: this.state.showWebcam }}
                            videoConstraints={videoConstraints} />

                        {this.state.screenshot ? <img src={this.state.screenshot} /> : null}

                        <br />
                        <br />
                        <button style={{ margin: '0px 10px 0px 0px', width: "100px" }} className="btn btn-info" onClick={this.screenshot.bind(this)}>Capture</button>
                        <Button style={{ margin: '0px 10px 0px 0px', width: "100px" }} variant="secondary" onClick={this.handleClose}>
                            Save
                        </Button>
                        <Button style={{ width: "100px" }} variant="danger" onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Body>

                </Modal>



                <Modal show={this.state.show}
                    onHide={this.handleClose}
                    className="text-center"
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header>
                        <Modal.Title><h2>Privacy Notice</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body><p><b>Privacy Notice: Digital Visitor Application</b></p>
                        <span style={{ fontSize: "12px" }}>By accepting the Digital Visitor Privacy Notice you acknowledge and agree that Vodacom is required to collect your personal information  including your name, surname, contact details, identity number, email address, photo, as well as your designated company’s information (where applicable) in order to allow you to gain access to our buildings. The information provided herein will be processed in accordance with the prevailing privacy legislations in South Africa and Vodacom’s Data Protection and Privacy Policy. By registering and submitting the required information you acknowledge and agree that your personal information may be processed for access purposes only to any of Vodacom’s buildings. Your personal information will not be disclosed to any unauthorized third party and/or outside of the country without your knowledge and consent. You hereby further acknowledge and agree that the processing of your personal information might be shared with other Vodacom business units for historic and statistic purposes, where necessary. Failure to accept this privacy notice will result in the prohibition of access to any Vodacom building.</span>
                        <p style={{ fontSize: "12px" }}>Please refer to our privacy statement for more information.</p>
                        <p></p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Accept
                        </Button>
                        <Button variant="danger" onClick={this.handleReject}>
                            Reject
                        </Button>

                    </Modal.Footer>
                </Modal>
                <Footers></Footers>
            </div>
        );
    }
}

export default register;