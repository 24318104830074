import React, { Component } from 'react';
import axios from "axios";
import { Redirect } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Cookies from 'universal-cookie';
import Visitor from './visitor';
import Modal from "react-bootstrap/Modal";
import Footer from './footer';
import StarsRating from 'stars-rating'
import Table from "react-bootstrap/Table";
import { v4 as uuidv4 } from 'uuid';

const ratingChanged = (newRating) => {
  ratingValue = newRating;
}

const configFile = require('../config.json');
const cookies = new Cookies();
let callCount = 1;
let ratingValue = "0";

class addVisitor extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showInfo: false,
      redirect: false,
      visitorsLoaded: false,
      searchState: '',
      surveyQuestion: '',
      surveyTitle: '',
      btnsDisabled: true,
      showRating: 'none',
      directTo: "/visitorwelcome",
      newvisitor: {
        "username": "",
        "id": ""
      },
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getUTCFullYear(),
      hostCountry: cookies.get('DVCountry'),
      visitors: []
    };
    this.handleAddVisitor = this.handleAddVisitor.bind(this);
    this.handleSendMessage = this.handleSendMessage.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  componentDidMount = () => {
    this.setState({ searchState: "Loading..." });
    setTimeout(function () {
      this.fetchVisitor();
      this.getSurvey();
    }.bind(this), 2000);

    setTimeout(function () {
      this.handleSendMessage();
    }.bind(this), 1000*120);

  }

  sendEmailMessage = () => {
    const visitorsCount = this.state.visitors.length;
    const hostEmail = cookies.get('DVHostMail');
    const hostName = cookies.get('DVHostName');
    const building = cookies.get('DVBuilding');

    if (visitorsCount > 0) {
      let visitorParty = " ";
      this.state.visitors.map((visitor, index) =>
        visitorParty = visitorParty + ' ' + visitor.visitorname + ' ' + visitor.visitorlastname + ',');
      
      var usid = localStorage.getItem('usersession');
      var data = JSON.stringify(
        {
          "session": usid,
          "hostEmail": hostEmail,
          "hostName": hostName,
          "visitors": visitorParty.slice(0, -1),
          "building": building
        });

      var config = {
        method: 'post',
        url: `${configFile.api.backendUrl}/hostemail`,
        headers: {
          'Content-Type': 'application/json',
          "x-api-key": `${configFile.api.xapikey}`
        },
        data: data
      };

      axios(config)
        .then(function (response) {
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  handleAddVisitor() {
    callCount = 1;
    this.setState({ redirect: true });
    this.setState({ directTo: "/visitorwelcome" });
  }

  handleSendMessage() {
    this.sendEmailMessage();

    callCount = 1;
    if (this.state.visitors.length > 0) {
      this.setState({ showInfo: true });
    } else {
      this.setState({ redirect: true });
      this.setState({ directTo: "/dvHost" });
    }
  }

  handleOk() {
    this.handleAddSurveyResult();
    cookies.set("DVHostPhoto", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostName", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostMail", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVHostMobile", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitkey", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitdate", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitreason", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitorType", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitVisitors", '', { path: '/', secure: true, sameSite: "lax" });
    cookies.set("DVvisitDuration", '', { path: '/', secure: true, sameSite: "lax" });

    this.setState({ redirect: true });
    this.setState({ directTo: "/dvHost" });
  }

  handleAddSurveyResult = async () => {
    if (this.state.showRating === "block") {
      const curTime = new Date().toLocaleString();
      const guid = uuidv4();
      const id = guid;
      let firstname = "";
      let lastname = "";
      let mobile = "";
      let email = "";
      let idno = "";

      if (cookies.get("DVvisitorType") === 'Unregistered') {
        firstname = "Anonymous";
        lastname = "Anonymous";
        mobile = "Anonymous";
        email = "Anonymous";
        idno = "Anonymous";
      } else {
        firstname = this.props.location.state.firstname;
        lastname = this.props.location.state.lastname;
        mobile = this.props.location.state.mobile;
        email = this.props.location.state.email;
        idno = this.props.location.state.id;
      }

      // add call to AWS API Gateway add visitor endpoint here
      let currentTimeInMilliseconds = " ";
      currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 
      try {
        const params = {
          "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
          "timestamps": currentTimeInMilliseconds,
          "id": guid,
          "firstname": firstname,
          "lastname": lastname,
          "mobile": mobile,
          "email": email,
          "idno": idno,
          "surveyquestion": this.state.surveyQuestion,
          "surveyresult": "" + ratingValue + "",
          "recorddate": curTime,
          "surveytitle": this.state.surveyTitle,
          "country": this.state.hostCountry,
          "username": localStorage.getItem('username'),
          "usid": localStorage.getItem('usersession')
        };

        await axios.post(`${configFile.api.backendUrl}/surveyquestion`, params, {
          "headers": {
            "x-api-key": `${configFile.api.xapikey}`
          }
        });

      } catch (err) {
        console.log(`An error has occurred: ${err}`);
      }
    }
  }

  fetchVisitor = async () => {

    const visitKey = cookies.get('DVvisitkey');
    const params = {
      "newid": this.state.currentYear.toString() + (this.state.currentMonth).toString(),
      "visitkey": visitKey
    };
    try {
      const res = await axios.post(`${configFile.api.backendUrl}/visitsvisitorquery`, params, {
        "headers": {
          "x-api-key": `${configFile.api.xapikey}`
        }
      });

      if (res.data.length > 0) {
        this.setState({ searchState: "" });
        this.setState({ btnsDisabled: false });
        cookies.set('DVvisitVisitors', res.data, { path: '/', secure: true, sameSite: "lax" });
      }

      this.setState(
        {
          visitors: res.data,
          visitorsLoaded: true
        },
      );

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      this.setState({ visitorsLoaded: true });
    }

    this.setState({ btnsDisabled: false });
    if (this.state.visitors.length === 0) {
      this.setState({ searchState: "" });
    }
  }

  getSurvey = async () => {
    try {
      const res = await axios.get(`${configFile.api.backendUrl}/surveyquestion/` + this.state.hostCountry, {
        "headers": {
          "x-api-key": `${configFile.api.xapikey}`
        }
      });
      if (res.data.length > 0) {
        this.setState({ showRating: "block" });
        this.setState({ surveyTitle: res.data[0].title });
        this.setState({ surveyQuestion: res.data[0].question });
      } else {
        this.setState({ surveyTitle: "" });
        this.setState({ surveyQuestion: "" });
        this.setState({ showRating: "none" });
      }
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          hostName: cookies.get('DVHostName'),
          hostMobile: cookies.get('DVHostMobile'),
          hostEmail: cookies.get('DVHostMail'),
          hostPhoto: cookies.get('DVHostPhoto')
        }
      }} />;
    }
    return (
      <div className="text-center">

        <h3>Would you like to add a visitor to your group visiting Vodacom premises?</h3>

        <br></br>

        <Container>
          <Row>
            <Col className="text-right"></Col>
            <Col xs={6}>

              <Button onClick={this.handleAddVisitor} variant="danger">Yes</Button>{' '}
              <Button onClick={this.handleSendMessage} variant="secondary" disabled={this.state.btnsDisabled}>No</Button>{' '}

            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col className="text-right"></Col>
            <Col xs={6}>
              <br />
              <p>Visitors:</p>
              <div className="tile is-child box notification is-success">
              </div>
              <div className="form-group">
                <span style={{ color: "red" }}>{this.state.searchState}</span>
              </div>
              {
                this.state.visitors.map((visitor, index) =>
                  <Visitor
                    visitorname={visitor.visitorname}
                    visitorlastname={visitor.visitorlastname}
                    key={visitor.id}
                  />)
              }
            </Col>
            <Col></Col>
          </Row>
        </Container>

        <Modal
          show={this.state.showInfo}
          onHide={this.handleClose}
          className="text-center"
          backdrop="static"
          keyboard={false}>

          <Modal.Body>
            <p>
              <span style={{ fontWeight: "bold" }}>Your host has been notified of your arrival, please remain at reception.</span>
            </p>
            <Table className="table-borderless" style={{ width: "100%", textAlign: "center" }}>
              <tbody>
                <tr>
                  <td colSpan="3">
                    {this.state.surveyQuestion}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "25%", textAlign: "center" }}></td>
                  <td style={{ width: "50%", textAlign: "center" }}>
                    <div style={{ display: this.state.showRating }}>
                      <StarsRating
                        count={10}
                        onChange={ratingChanged}
                        size={24}
                        color2={'#ffd700'} />
                    </div>
                  </td>
                  <td style={{ width: "25%", textAlign: "center" }}></td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer >
            <Button variant="secondary" onClick={this.handleOk}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Footer></Footer>
      </div>
    )
  }
}

export default addVisitor;