import React, { Component } from 'react';
import ADMINICO from "../admin.png";
import mobileICO from "../calls-contacts.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Footers from './footer';
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import Modal from 'react-bootstrap/Modal';
import { Redirect } from 'react-router';
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Cookies from 'universal-cookie';

const config = require("../config.json");

class visitorReject extends Component {
  constructor(props, context) {
      super(props, context);
        
      const idLogin = this.props.location.state.id;
      const emailLogin = this.props.location.state.email;

      this.handleShowCapture = this.handleShowCapture.bind(this);               
      this.handleNext = this.handleNext.bind(this);
      this.handleBack = this.handleBack.bind(this);

      this.state = {
        show: false,
        redirect: false,
        directTo: "/additionalVisitor",
        id: idLogin,
        email: emailLogin,
        mobile: "",
        name: "",
        surname: "",
        comments: "",
        errMsg_Mobile: "none",
        errMsg_name: "none",
        errMsg_lastname: "none",  
        errMsg_Concern: "none",
        currentMonth: new Date().getMonth()+1,
        currentYear: new Date().getUTCFullYear()
      };   
  }

  onMobileChange = event => this.setState({ mobile : event.target.value });
  onNameChange = event => this.setState({ name: event.target.value });
  onIdChange = event => this.setState({ id: event.target.value });
  onSurnameChange = event => this.setState({ surname: event.target.value });
  onCommentsChange = event => this.setState({ comments: event.target.value });
   
  handleAddComments = async () => {
    // add call to AWS API Gateway add visitor endpoint here
    const guid = uuidv4();
   // const curTime = new Date().toLocaleString();     
    let currentTimeInMilliseconds=" ";
    currentTimeInMilliseconds=Date.now().toString(); // unix timestamp in milliseconds 

    try {
        const cookies = new Cookies();
        const params = {
            "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
            "timestamps": currentTimeInMilliseconds,
            "id": guid,
            "firstname": this.state.name,
            "lastname": this.state.surname,
            "contactno": this.state.mobile,
            "message": this.state.comments,
            "datecreated": new Date().toLocaleString(),
            "username": localStorage.getItem('username'),
            "usid": localStorage.getItem('usersession'),
            "country": cookies.get("DVCountry")
        };

        await axios.post(`${config.api.backendUrl}/rejectpolicy`, params,{"headers": {
          "x-api-key": `${config.api.xapikey}`
        }});       
               
        
    }catch (err){
        console.log(`An error has occurred: ${err}`);
    }

  }

  handleChange = e => {
    this.form.validateFields(e.target);
  }

    handleShowCapture() {
		this.setState({ show: true });
    }

    handleNext() {
		this.setState({ redirect: true });
    }

    handleBack() {
        this.setState({ directTo: "/registerSignIn"});
		  this.setState({ redirect: true });
    }
  
    contactSubmit = e => {
      e.preventDefault();        
  

      let formValid = true;

        if(this.state.name === '') {     
            this.setState({errMsg_name: "block"});    
            formValid = false;                
        }else{            
            this.setState({errMsg_name: "none"});
        } 
        
        if(this.state.surname === '') {     
            this.setState({errMsg_lastname: "block"});    
            formValid = false;                
        }else{            
            this.setState({errMsg_lastname: "none"});
        } 

        if(this.state.comments === '') {     
          this.setState({errMsg_Concern: "block"});    
          formValid = false;                
      }else{            
          this.setState({errMsg_Concern: "none"});
      } 
        

        if(this.state.mobile.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {     
            this.setState({errMsg_Mobile: "none"});                    
        }else{
            this.setState({ mobile: "" });
            formValid = false;
            this.setState({errMsg_Mobile: "block"});
        } 

        
      
    
        if(formValid)  {
            this.handleAddComments();
            this.handleShowCapture();
        }

      
    }

    render() { 
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.directTo,
                state: { 
                    id: this.state.id,
                    email: this.state.email
                  }
              }} />;
        }
        return (<div>
      
            <FormWithConstraints
                    ref={form => this.form = form}
                    onSubmit={this.contactSubmit}
                    noValidate>

<div className="text-center">
  <p>
<h3 style={{ margin: '20px 0px 0px 0px'  }}>Rejected Privacy Notice</h3>
  </p>
  <p><span>Your access will be denied to the Vodacom premise.</span></p>
  <p><span>Could you please indicate your concerns capturing the required information</span></p>
         <br></br> 
               
          <Container>         
          <Row>
            <Col></Col>
            <Col xs={6}>

            <div className="form-group">
              <img src={ADMINICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }} />
             
              <input
                name="FirstName"
                id="FirstName"
                type="text"
                placeholder = "First Name"
                className="form-control+matching-text-style"
                onChange={this.onNameChange}
                style={{ width: '85%'}}
                required
              />
            </div> 
            <div style={{ display: this.state.errMsg_name, color: 'red' }}><p>First Name is mandatory, please complete</p></div>                            
            </Col>
            <Col></Col>
          </Row>  
          <Row>
            <Col></Col>
            <Col xs={6}>

            <div className="form-group">
              <img src={ADMINICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }} />
             
              <input
                name="Surname"
                id="Surname"
                type="text"
                placeholder = "Last Name"
                className="form-control+matching-text-style"
                onChange={this.onSurnameChange}
                style={{ width: '85%'}}
                required
              />
            </div> 
            <div style={{ display: this.state.errMsg_lastname, color: 'red' }}><p>Last Name is mandatory, please complete</p></div>                        
            </Col>
            <Col></Col>
          </Row>  
          <Row>
            <Col></Col>
            <Col xs={6}>

            <div className="form-group">
              <img src={mobileICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }} />
             
              <input
                name="Mobile"
                id="Mobile"
                type="text"
                placeholder = "Contact Number"
                className="form-control+matching-text-style"
                onChange={this.onMobileChange}
                style={{ width: '85%'}}
                required
              />
            </div> 
            <div style={{ display: this.state.errMsg_Mobile, color: 'red' }}><p>Contact number is mandatory, please complete</p></div>
            </Col>
            <Col></Col>
          </Row> 
          <Row>
            <Col></Col>
            <Col xs={6}>

            <div className="form-group">
            <textarea cols="30" rows="5"
                className="comments" 
                placeholder="Capture concern" 
                onChange={this.onCommentsChange}
                name="concern"
                id="concern"
                required>
            </textarea>
            <div style={{ display: this.state.errMsg_Concern, color: 'red' }}><p>Concern is mandatory, please complete</p></div>
            <p/>
            </div> 
            </Col>
            <Col></Col>
          </Row> 
          <Row style={{margin: '0px 0px 90px 0px'}}>
            <Col></Col>
            <Col xs={8}>
            <Button variant="danger" onClick={this.handleBack}  style={{ width: '130px'  }}>Back</Button>{' '} 
            <Button variant="secondary" type="submit"  style={{ width: '130px' }}>Submit</Button>{' '} 
            </Col>
            <Col></Col>
          </Row>
        </Container> 

        </div> 
            </FormWithConstraints>

        <Modal show={this.state.show}                 
                dialogClassName="my-modal"
                backdrop="static"
                keyboard={false}>				      
              <Modal.Body>    
              <br/>
              <p>
                    <span className="popupConcernMessageTitle">Concern submitted</span>
                </p>      
                <br/>
                <br/> 
              <p><span >Thank you for your feedback we will look into your feedback.</span></p>     
              <br/>
              <br/>
                <Button variant="secondary" onClick={this.handleNext} style={{ width: '150px'}}> Next</Button>
                <br/>
              </Modal.Body>             
			</Modal>

            <Footers></Footers> 
        </div> 
               
            );
    }
}
 
export default visitorReject;