import React, { Component } from 'react';
import { Redirect } from 'react-router';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Headers from './header';
import Footers from './footer';
import profileICO from "../admin.png";
import companyICO from "../enterprise.png";
import mobileICO from "../calls-contacts.png";
import emailICO from "../mail-new.png";
import idICO from "../privacy-or-confidential.png";
import picICO from "../photos.png";
import laptopICO from "../desktop.png";
import serialICO from "../shopping-checkout.png";
import carICO from "../car.png";
import Modal from 'react-bootstrap/Modal';
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import "./register.css";
import axios from "axios";
import Webcam from 'react-webcam';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const config = require('../config.json');
const videoConstraints = {
    facingmode: 'user'
};

class register extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleReject = this.handleReject.bind(this);
        this.handleShowCapture = this.handleShowCapture.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        const idLogin = this.props.location.state.id;
        const emailLogin = this.props.location.state.email;

        this.state = {
            show: false,
            // directTo: "/additionalQuestions",
            directTo: "/additionalVisitor",
            showCapture: false,
            showmobilematch: false,
            showemailmatch: false,
            screenshot: null,
            showWebcam: "block",
            errMsg_Mobile: "none",
            errMsg_name: "none",
            errMsg_lastname: "none",
            errMsg_company: "none",
            errMsg_email: "none",
            visitorsvalidate: [],
            newVisitor: {
                "id": idLogin,
                "firstname": this.props.location.state.firstname,
                "lastname": this.props.location.state.lastname,
                "company": this.props.location.state.company,
                "email": "",
                "laptop": "",
                "laptopserial": "",
                "mobile": this.props.location.state.mobile,
                "vehicle": "",
                "image": this.props.location.state.image
            },
            currentMonth: new Date().getMonth() + 1,
            currentYear: new Date().getUTCFullYear()
        };
    }

    handleCancel() {
        this.setState({ redirect: true });
        this.setState({ directTo: "/registerSave" });
    }

    screenshot() {
        // access the webcam trough this.refs
        var screenshot = this.refs.webcam.getScreenshot();
        this.setState({ screenshot: screenshot });
        this.setState({ showWebcam: "none" });
    }

    handleAddVisitor = async () => {
        const guid = uuidv4();
        const id = this.state.newVisitor.id;
        // Update the visitor, as record already exist
        try {
            const params = {
                "id": id,
                "firstname": this.state.newVisitor.firstname,
                "lastname": this.state.newVisitor.lastname,
                "company": this.state.newVisitor.company,
                "email": this.state.newVisitor.email,
                "laptop": this.state.newVisitor.laptop,
                "laptopserial": this.state.newVisitor.laptopserial,
                "mobile": this.state.newVisitor.mobile,
                "vehicle": this.state.newVisitor.vehicle,
                "image": this.state.screenshot,
                "unqkey": guid,
                "username": localStorage.getItem('username'),
                "usid": localStorage.getItem('usersession')
            };
            await axios.put(`${config.api.backendUrl}/visitors`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });
            this.fetchVisitor();
        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }

        //this.setState({ newVisitor: { "id": "", "firstname": "", "lastname": "", "company": "", "email": "", "laptop": "", "laptopserial": "", "mobile": "", "vehicle": ""}});
    }

    onAddVisitorNameChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "firstname": event.target.value } });
    onAddVisitorIdChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "id": event.target.value } });
    onAddVisitorLastnameChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "lastname": event.target.value } });
    onAddVisitorCompanyChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "company": event.target.value } });
    onAddVisitorEmailChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "email": event.target.value } });
    onAddVisitorLaptopChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "laptop": event.target.value } });
    onAddVisitorLaptopserialChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "laptopserial": event.target.value } });
    onAddVisitorMobileChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": event.target.value } });
    onAddVisitorVehicleChange = event => this.setState({ newVisitor: { ...this.state.newVisitor, "vehicle": event.target.value } });

    handleClose() {
        this.setState({ show: false });
        this.setState({ showCapture: false });
        this.setState({ showmobilematch: false });
        this.setState({ showemailmatch: false });
        this.setState({ showWebcam: "block" })
    }

    handleReject() {
        this.setState({ directTo: "reject" });
        this.setState({ show: false });
        this.setState({ redirect: true });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleShowCapture() {
        this.setState({ showCapture: true });
    }

    componentDidMount = () => {
        this.setState({ screenshot: "" });
        this.setState({ newVisitor: { ...this.state.newVisitor, "laptop": " " } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "laptopserial": " " } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": " " } });
        this.setState({ newVisitor: { ...this.state.newVisitor, "vehicle": "" } });

        //this.handleShow();
    }

    handleChange = e => {
        this.form.validateFields(e.target);
    }

    registerSubmit = e => {
        e.preventDefault();

        let formValid = true;

        if (this.state.newVisitor.firstname === '') {
            this.setState({ errMsg_name: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_name: "none" });
        }

        if (this.state.newVisitor.lastname === '') {
            this.setState({ errMsg_lastname: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_lastname: "none" });
        }

        if (this.state.newVisitor.company === '') {
            this.setState({ errMsg_company: "block" });
            formValid = false;
        } else {
            this.setState({ errMsg_company: "none" });
        }

        if (this.state.newVisitor.mobile.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
            this.setState({ errMsg_Mobile: "none" });
        } else {
            this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": "" } });
            formValid = false;
            this.setState({ errMsg_Mobile: "block" });
        }

        if (this.state.newVisitor.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            this.setState({ errMsg_email: "none" });
        } else {
            this.setState({ errMsg_email: "block" });
            formValid = false;
        }

        if (formValid) {
            this.handleValidateVisitor();
            const id = this.state.newVisitor.id;
            const name = this.state.newVisitor.firstname;
            const lastname = this.state.newVisitor.lastname;
        }
    }

    handleValidateVisitor = async () => {
        // Update the visitor, as record already exist
        try {
            const params = {
                "email": this.state.newVisitor.email,
                "mobile": this.state.newVisitor.mobile,
                "session": localStorage.getItem('usersession')
            };

            //await axios.post(`${config.api.visitorUrl}/visitors/{id}`, params);        
            const res = await axios.post(`${config.api.backendUrl}/visitorvalidate/`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

            this.setState({ visitorsvalidate: res.data }, function () {
            });

            if (this.state.visitorsvalidate.length == 0) {
                // No matches found
                this.handleAddVisitor();
            } else {
                if (res.data[0].email === undefined) {
                    //  console.log('email undefined');
                } else {
                    //  console.log('email match');
                    this.setState({ showemailmatch: true });
                }
                if (res.data[0].mobile === undefined) {
                    // console.log('mobile undefined');
                } else {
                    // console.log('mobile match');
                    this.setState({ showmobilematch: true });
                }
            }

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }

        //this.setState({ newVisitor: { "id": "", "firstname": "", "lastname": "", "company": "", "email": "", "laptop": "", "laptopserial": "", "mobile": "", "vehicle": ""}});
    }

    fetchVisitor = async () => {
        try {
            // console.log(this.state.result);
            const res = await axios.get(`${config.api.backendUrl}/visitor/` + this.state.result, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });
            this.setState({ visitors: res.data }, function () {
            });

            if (this.state.visitors.length == 0) {
                this.setState({ directTo: "/visitorwelcome" });
                this.setState({ redirect: true });
            } else {
                this.setState({ newVisitor: { ...this.state.newVisitor, "id": res.data[0].id } })
                this.setState({ newVisitor: { ...this.state.newVisitor, "firstname": res.data[0].firstname } })
                this.setState({ newVisitor: { ...this.state.newVisitor, "lastname": res.data[0].lastname } })
                this.setState({ newVisitor: { ...this.state.newVisitor, "email": res.data[0].email } });
                this.setState({ newVisitor: { ...this.state.newVisitor, "company": res.data[0].company } })
                this.setState({ newVisitor: { ...this.state.newVisitor, "mobile": res.data[0].mobile } })
                this.setState({ newVisitor: { ...this.state.newVisitor, "image": res.data[0].image } })
                cookies.set("DVvisitorType", 'Registered', { path: '/', secure: true, sameSite: "lax" });
                this.handleSaveVisitor();
            }

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }

    handleSaveVisitor() {
        const guid = uuidv4();
        this.handleAddVisitsVisitor(
            this.state.id,
            this.state.firstname,
            this.state.lastname,
            guid,
            this.state.email,
            this.state.company,
            this.state.mobile,
            this.state.image);

        this.setState({ directTo: "/additionalVisitor" });
        this.setState({ redirect: true });
    }

    handleAddVisitsVisitor = async (id, firstname, lastname, guid, email, company, mobile, image) => {
        const curTime = new Date().toLocaleString();
        let currentTimeInMilliseconds = " ";
        currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 
        try {
            const params = {
                "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
                "timestamps": currentTimeInMilliseconds,
                "id": guid,
                "visitkey": cookies.get('DVvisitkey'),
                "visitorid": id,
                "visitorname": firstname,
                "visitorlastname": lastname,
                "visitormobile": mobile,
                "visitoremail": email,
                "visitorcompany": company,
                "visitortype": cookies.get("DVvisitorType"),
                "country": cookies.get("DVCountry"),
                "building": cookies.get("DVBuilding"),
                "visitdate": cookies.get("DVvisitdate"),
                "hostname": cookies.get("DVHostName"),
                "visitreason": cookies.get("DVvisitreason"),
                "visitorimage": image,
                "visittime": cookies.get("DVvisitDuration"),
                username: localStorage.getItem('username'),
                usid: localStorage.getItem('usersession')
            };

            await axios.post(`${config.api.backendUrl}/visitsvisitor/${id}`, params, {
                "headers": {
                    "x-api-key": `${config.api.xapikey}`
                }
            });

        } catch (err) {
            console.log(`An error has occurred: ${err}`);
        }
    }


    render() {
        if (this.state.redirect) {
            return <Redirect push to={{
                pathname: this.state.directTo,
                state: {
                    id: this.state.newVisitor.id,
                    email: this.state.newVisitor.email,
                    firstname: this.state.newVisitor.firstname,
                    lastname: this.state.newVisitor.lastname,
                    company: this.state.newVisitor.company,
                    mobile: this.state.newVisitor.mobile,
                    image: this.state.screenshot
                }
            }} />;
        }
        return (
            <div>
                <div className="text-center">
                    <h3 style={{ margin: '50px 0px 0px 0px' }}>Registration</h3>
                    <br></br>
                    <FormWithConstraints
                        ref={form => this.form = form}
                        onSubmit={this.registerSubmit}
                        noValidate>
                        <div className="container">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <img src={profileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="name"
                                        id="name"
                                        type="text"
                                        placeholder="First Name"
                                        className="form-control+matching-text-style"
                                        required
                                        style={{ width: '85%' }}
                                        value={this.state.newVisitor.firstname}
                                        onChange={this.onAddVisitorNameChange}
                                    />
                                    <div style={{ display: this.state.errMsg_name, color: 'red' }}><p>First Name is mandatory, please complete</p></div>
                                </div>
                                <div className="form-group col-md-6">
                                    <img src={profileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="surname"
                                        id="surname"
                                        type="text"
                                        placeholder="Last Name"
                                        className="form-control+matching-text-style"
                                        required
                                        style={{ width: '85%' }}
                                        value={this.state.newVisitor.lastname}
                                        onChange={this.onAddVisitorLastnameChange}
                                    />
                                    <div style={{ display: this.state.errMsg_lastname, color: 'red' }}><p>Last Name is mandatory, please complete</p></div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <img src={companyICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="Company"
                                        id="Company"
                                        type="text"
                                        placeholder="Company Name"
                                        className="form-control+matching-text-style"
                                        value={this.state.newVisitor.company}
                                        onChange={this.onAddVisitorCompanyChange}
                                        style={{ width: '85%' }}
                                        required
                                    />
                                    <div style={{ display: this.state.errMsg_company, color: 'red' }}><p>Company name is mandatory, please complete</p></div>
                                </div>
                                <div className="form-group col-md-6">
                                    <img src={mobileICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="Mobile"
                                        id="Mobile"
                                        type="text"
                                        placeholder="Contact Number"
                                        className="form-control+matching-text-style"
                                        value={this.state.newVisitor.mobile}
                                        onChange={this.onAddVisitorMobileChange}
                                        style={{ width: '85%' }}
                                        required
                                    />
                                    <div style={{ display: this.state.errMsg_Mobile, color: 'red' }}><p>Invalid Contact number, please complete</p></div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <img src={emailICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="Email"
                                        id="Email"
                                        type="email"
                                        placeholder="Email address *"
                                        className="form-control+matching-text-style"
                                        value={this.state.newVisitor.email}
                                        onChange={this.onAddVisitorEmailChange}
                                        style={{ width: '85%' }}
                                        required
                                    />
                                    <div style={{ display: this.state.errMsg_email, color: 'red' }}><p>Invalid Email, please complete</p></div>
                                </div>
                                <div className="form-group col-md-6">
                                    <img src={idICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="Id"
                                        id="Id"
                                        type="text"
                                        placeholder="RSA ID/Passport number"
                                        className="form-control+matching-text-style"
                                        required
                                        style={{ width: '85%' }}
                                        value={this.state.newVisitor.id}
                                        readOnly
                                    />
                                    <FieldFeedbacks for="Id">
                                        <FieldFeedback when="*" />
                                    </FieldFeedbacks>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <img src={picICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="avatar"
                                        id="avatar"
                                        type="text"
                                        placeholder="Capture Profile Image"
                                        className="form-control+matching-text-style"
                                        onChange={() => { }}
                                        style={{ width: '85%' }}
                                        onClick={this.handleShowCapture}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <img src={laptopICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="laptop"
                                        id="laptop"
                                        type="text"
                                        placeholder="Laptop name"
                                        className="form-control+matching-text-style"
                                        value={this.state.newVisitor.laptop}
                                        onChange={this.onAddVisitorLaptopChange}
                                        style={{ width: '85%' }}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <img src={serialICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="serial"
                                        id="serial"
                                        type="text"
                                        placeholder="Laptop serial number"
                                        className="form-control+matching-text-style"
                                        value={this.state.newVisitor.laptopserial}
                                        onChange={this.onAddVisitorLaptopserialChange}
                                        style={{ width: '85%' }}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <img src={carICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                                    <input
                                        name="Vehicle"
                                        id="Vehicle"
                                        type="text"
                                        placeholder="Vehicle registration"
                                        className="form-control+matching-text-style"
                                        value={this.state.newVisitor.vehicle}
                                        onChange={this.onAddVisitorVehicleChange}
                                        style={{ width: '85%' }}
                                    />
                                </div>
                            </div>
                            <div className="text-center">
                                <button style={{ margin: '0px 0px 70px 0px' }} className="btn btn-secondary" >Register</button>{' '}
                                <button
                                    style={{ margin: '0px 0px 70px 10px' }}
                                    className="btn btn-danger"
                                    onClick={this.handleCancel}>
                                    Cancel
                                </button>
                            </div>

                        </div>
                    </FormWithConstraints>

                    <div className="form-row">

                    </div>
                </div>

                <Modal show={this.state.showCapture}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                    style={{ textAlign: "center" }}>

                    <Modal.Body style={{ textAlign: "center" }}>

                        <Webcam
                            audio={false}
                            ref='webcam'
                            screenshotFormat="image/jpeg"
                            height={"420px"}
                            width={"470px"}
                            facingmode="user"
                            style={{ display: this.state.showWebcam }}
                            videoConstraints={videoConstraints} />

                        {this.state.screenshot ? <img src={this.state.screenshot} /> : null}

                        <br />
                        <br />
                        <button style={{ margin: '0px 10px 0px 0px', width: "100px" }} className="btn btn-info" onClick={this.screenshot.bind(this)}>Capture</button>
                        <Button style={{ margin: '0px 10px 0px 0px', width: "100px" }} variant="secondary" onClick={this.handleClose}>
                            Save
                        </Button>
                        <Button style={{ width: "100px" }} variant="danger" onClick={this.handleClose}>
                            Cancel
                        </Button>
                    </Modal.Body>

                </Modal>

                <Modal show={this.state.show}
                    onHide={this.handleClose}
                    className="text-center"
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header>
                        <Modal.Title><h2>Privacy Notice</h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body><p><b>Privacy Notice: Digital Visitor Application</b></p>
                        <span style={{ fontSize: "12px" }}>By accepting the Digital Visitor Privacy Notice you acknowledge and agree that Vodacom is required to collect your personal information  including your name, surname, contact details, identity number, email address, photo, as well as your designated company’s information (where applicable) in order to allow you to gain access to our buildings. The information provided herein will be processed in accordance with the prevailing privacy legislations in South Africa and Vodacom’s Data Protection and Privacy Policy. By registering and submitting the required information you acknowledge and agree that your personal information may be processed for access purposes only to any of Vodacom’s buildings. Your personal information will not be disclosed to any unauthorized third party and/or outside of the country without your knowledge and consent. You hereby further acknowledge and agree that the processing of your personal information might be shared with other Vodacom business units for historic and statistic purposes, where necessary. Failure to accept this privacy notice will result in the prohibition of access to any Vodacom building.</span>
                        <p style={{ fontSize: "12px" }}>Please refer to our privacy statement for more information.</p>
                        <p></p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Accept
                        </Button>
                        <Button variant="danger" onClick={this.handleReject}>
                            Reject
                        </Button>

                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showemailmatch}
                    onHide={this.handleClose}
                    className="text-center"
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Body>
                        <p style={{ color: "red" }}><b>Invalid email captured</b></p>
                        <span style={{ fontSize: "12px" }}>The captured email is already linked to an existing ID number. <br />Please capture an alternative email.</span>
                        <p></p>
                        <Button style={{ width: "150px" }} variant="danger" onClick={this.handleClose}>
                            Ok
                        </Button>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showmobilematch}
                    onHide={this.handleClose}
                    className="text-center"
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Body>
                        <p style={{ color: "red" }}><b>Invalid mobile number captured</b></p>
                        <span style={{ fontSize: "12px" }}>The captured mobile number is already linked to an existing ID number. <br />Please capture an alternative mobile number </span>
                        <p></p>
                        <Button style={{ width: "150px" }} variant="danger" onClick={this.handleClose}>
                            Ok
                        </Button>
                    </Modal.Body>
                </Modal>

                <Footers></Footers>
            </div>
        );
    }
}

export default register;