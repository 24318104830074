import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import ENTERPRICEPIC from "../enterprise.png";
import Cookies from 'universal-cookie';
import { Redirect } from "react-router";
import {
  FormWithConstraints,
  FieldFeedbacks,
  Async,
  FieldFeedback,
} from "react-form-with-constraints";
import spacer from "../spacer.png";

// const options = [
//   { value: 'corporatePark', label: 'Corporate Park' },
//   { value: 'businessPark', label: 'Business Park' },
//   { value: 'vodaworld', label: 'Vodaworld' },
// ];

class regionSel extends Component {
  constructor(props, context) {
    super(props, context);

    const userRegion = this.props.location.state.region;
    const userCountry = this.props.location.state.country;
    
    this.handleBack = this.handleBack.bind(this);

    // console.log(userRegion);
    // console.log(userCountry);

    this.state = {
      redirect: false,
      directTo: "/dvHost",
      // country: this.props.location.state.country,
      // region: this.props.location.state.region,
      // buildings: this.props.location.state.buildings,
      country: localStorage.getItem('country'),
      region: localStorage.getItem('region'),
      buildings: localStorage.getItem('buildings'),
      selectedOption: null,
      showError: "none"
    };
  }

  handleBack() {
    const cookies = new Cookies();
    cookies.set('DVBuilding', "", { path: '/', secure: false, sameSite: "lax" });
    this.setState({ directTo: "/" })
    this.setState({ redirect: true });
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    // console.log(`Option selected:`, selectedOption);
    const cookies = new Cookies();
    cookies.set('DVBuilding', selectedOption.label, { path: '/', secure: true, sameSite: "lax" });
    cookies.set('DVCountry', this.state.country, { path: '/', secure: true, sameSite: "lax" });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.form.validateFields();
    if (this.state.selectedOption === null) {
      this.setState({ showError: 'block' });
    } else {
      if (this.form.isValid()) {
        this.setState({ redirect: true });
      }
    }
  };

  removeWhiteSpace(stringfordb) {
    var string = stringfordb;
    string = string.replace(/ /g,"_");
    return string;
  }

  removeUnderscore(stringfromdb) {
    var string = stringfromdb;
    string = string.replace(/_/g," ");
    return string;
  }

  render() {
    const { selectedOption } = this.state;

    var productArr = [];
    var sentence = ""
    //sentence.split(" ");
    // console.log(sentence.split(" ")); 
    var Data = this.state.buildings.split(","),
      MakeItem = function (X) {
        return productArr.push({ value: X, label: X });;
      };
    // console.log(productArr);  
    // console.log(options);  

    if (this.state.redirect) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.directTo
          }}
        />
      );
    }

    return (
      <div className="text-center">
        <h2>Retrieved region for {this.removeUnderscore( this.state.country)}</h2>
        <p><span><b> {this.removeUnderscore( this.state.region )}</b></span></p>

        <FormWithConstraints
          ref={(form) => (this.form = form)}
          onSubmit={this.handleSubmit}
          noValidate>

          <Container>
            <Row>
              <Col className="text-right"><img src={ENTERPRICEPIC} style={{ width: '20px' }} /></Col>
              <Col xs={6}>
                <div className="form-group">
                  <Select
                    value={selectedOption}
                    onChange={this.handleChange}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={productArr[0]}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isRtl={false}
                    isSearchable={true}
                    name="color"
                    options={productArr}
                    required
                    placeholder="Select a building... "
                  />
                </div>
                <select hidden>{Data.map(MakeItem)}</select>
                <p />
                <span style={{ margin: '0px 0px 10px 0px', display: this.state.showError, color: 'red' }}>Please select a building.</span>
                <Button onClick={this.handleBack} variant="danger">Cancel</Button>{' '}
                <button className="btn btn-secondary" >Next</button>

              </Col>
              <Col></Col>
            </Row>
          </Container>

        </FormWithConstraints>

        <div className="main-footer">
          <div className="container">
            <img src={spacer}></img>
          </div>
          <div className="page-containerRight"></div>
        </div>

      </div>
    );
  }
}

export default regionSel;