import React, { Component } from 'react';
import ADMINICO from "../admin.png";
import PASSWICO from "../security.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import { Redirect } from 'react-router';
import axios from "axios";
import './loginFields.css';
import Cookies from 'universal-cookie';
import spacer from "../spacer.png";
import Show from "../show.png";
import Hide from "../hide.png";
import Modal from 'react-bootstrap/Modal';

const config = require('../config.json');
const cookies = new Cookies();

class Logins extends Component {
  constructor(props, context) {
    super(props, context);

    this.onClickPassword = this.onClickPassword.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
      showRoleError: false,
      directTo: "/otpPref",
      showVisitorDontExist: "none",
      showLogonError: "none",
      admins: [],
      username: "",
      email: "",
      password: "",
      mobile: "",
      country: "",
      region: "",
      buildings: "",
      buildingName: cookies.get('DVBuilding'),
      passwordType: "password",
      passwordIco: Show
    };
  }

  onClickPassword = e => {
    e.preventDefault();
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" });
      this.setState({ passwordIco: Hide });
    } else {
      this.setState({ passwordType: "password" });
      this.setState({ passwordIco: Show });
    }
  }

  handleClose() {
    this.setState({ showRoleError: false });
  }

  handleChange = e => {
    this.setState({ username: e.target.value.toLowerCase(), email: e.target.value.toLowerCase() });
    this.form.validateFields(e.target);
  }

  handleChangePassword = e => {
    this.setState({ password: e.target.value })
    this.form.validateFields(e.target);
  }

  adminSubmit = e => {
    e.preventDefault();

    this.form.validateFields();

    if (!this.form.isValid()) {
      // console.log('form is invalid: do not submit');
    } else {
      // console.log('form is valid: submit');
      this.fetchAdmins();
    }
  }

  fetchAdmins = async () => {
    try {
      const params = {
        "id": this.state.email.toLowerCase(),
        "password": this.state.password
      };
      const res = await axios.post(`${config.api.backendUrl}/adminuser`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });
      this.setState({ admins: res.data });

      if (this.state.admins.length == 0) {
        this.setState({ showLogonError: "block" });
      } else {
        if (res.status == "208") {
          const admin = { ...res.data[0] };
          localStorage.setItem('usersession', admin.tmpusersessionvalue);
          localStorage.setItem('userdisplayname', admin.display_name);

          localStorage.setItem('username', admin.username);
          this.setState({ showNotAllowed: "none" });
          this.setState({ showLogonError: "none" });
          this.setState({ redirect: true });
          cookies.set('DVCountry', admin.country);
          if (["Building Admin and Reception User", "Administratir"].includes(admin.userrole)) {
            this.setState({ id: admin.id });
            this.setState({ showRoleError: false });
            this.setState({ mobile: admin.mobile })
            this.setState({ username: admin.username });
            this.setState({ email: admin.email });
            this.setState({ country: admin.country });
            this.setState({ region: admin.buildingsregion });
            this.setState({ buildings: admin.buildings });

            localStorage.setItem('buildings', admin.buildings);
            localStorage.setItem('region', admin.buildingsregion);
            localStorage.setItem('country', admin.country);

            this.setState({ directTo: "/otpPref" }, function () {
              this.setState({ directTo: "/otpPref" });
              this.setState({ redirect: true });
            });
          } else {
            this.setState({ showRoleError: true });
          }
        } else {
          this.setState({ showLogonError: "block" });
        }
      }
    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  componentDidMount = () => {
    if ((this.state.buildingName == '') || (this.state.buildingName == undefined)) {
      const cookies = new Cookies();
      cookies.set('DVBuilding', "", { path: '/', secure: true, sameSite: "lax" });
    } else {
      this.setState({ directTo: "/dvHost" }, function () {
        this.setState({ directTo: "/dvHost" });
        this.setState({ redirect: true });
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          id: this.state.username,
          username: this.state.username,
          mobile: this.state.mobile,
          email: this.state.email,
          country: this.state.country,
          region: this.state.region,
          buildings: this.state.buildings
        }
      }} />;
    }
    return (<div>
      <div className="text-center">
        <p><span className="vodacomHeaderText">Reception Admin Login</span> </p>

        <br />
      </div>
      <FormWithConstraints
        ref={form => this.form = form}
        onSubmit={this.adminSubmit}
        noValidate>

        <Container>
          <Row>
            <Col></Col>
            <Col xs={6}>
              <div className="form-group">
                <img src={ADMINICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                <input
                  name="Username"
                  id="Username"
                  type="text"
                  placeholder="Email or Username *"
                  className="form-control+matching-text-style"
                  required onChange={this.handleChange}
                  style={{ width: '75%' }}
                />
                <FieldFeedbacks for="Username">
                  <FieldFeedback when="*" />
                </FieldFeedbacks>
              </div>

            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col xs={6}>
              <div className="form-group">
                <img src={PASSWICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                <input
                  name="Password"
                  id="Password"
                  type={this.state.passwordType}
                  placeholder="Password *"
                  className="form-control+matching-text-style"
                  required onChange={this.handleChangePassword}
                  style={{ width: '75%' }}
                />
                <img onClick={this.onClickPassword} src={this.state.passwordIco}></img>
                <FieldFeedbacks for="Password">
                  <FieldFeedback when="*" />
                </FieldFeedbacks>
              </div>
              <div style={{ display: this.state.showVisitorDontExist, color: 'red' }}><p>You have captured the incorrect visitor details. Please try again</p></div>
              <div style={{ display: this.state.showLogonError, color: 'red' }}><p>Invalid details captured. Please try again or email VisitorsAppSupport@vodacom.co.za</p></div>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col xs={6}>
              <div className="text-center">
                <button className="btn btn-secondary" style={{ width: '200px', margin: '0px 0px 90px 0px' }}>Login</button>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </FormWithConstraints>

      <div className="main-footer">
        <div className="container">
          <img src={spacer}></img>
        </div>
        <div className="page-containerRight"></div>
      </div>

      <Modal show={this.state.showRoleError}
        onHide={this.handleClose}
        className="text-center"
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <p style={{ color: "red" }}><b>Unassigned Building and Reception role</b></p>
          <span style={{ fontSize: "12px" }}>You do not have a building and reception user role.</span>
          <p></p>
          <Button style={{ width: "150px" }} variant="danger" onClick={this.handleClose}>
            Close
          </Button>
        </Modal.Body>
      </Modal>

    </div>
    );
  }
}

export default Logins;
