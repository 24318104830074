import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OTPClient from 'otp-client';
import OtpInput from 'react-otp-input';
import Headers from './header';
import Footers from './footer';
import axios from 'axios';
import './otpFields.css';
import { Redirect } from 'react-router';
import spacer from "../spacer.png";

const configFile = require('../config.json');

class otpPref extends Component {
  constructor(props, context) {
    super(props, context);

    const LoginId = this.props.location.state.id;
    const LoginMobile = this.props.location.state.mobile;
    const LoginEmail = this.props.location.state.email;

    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      otp: '',
      redirect: false,
      Id: this.props.location.state.id,
      Mobile: this.props.location.state.mobile,
      Email: this.props.location.state.email,
      Mode: this.props.location.state.mode,
      Country: this.props.location.state.country,
      Region: this.props.location.state.region,
      Buildings: this.props.location.state.buildings,
      target: this.props.location.state.target,
      showOTPError: "none",
      numInputs: 6,
      separator: '',
      isDisabled: false,
      hasErrored: false,
      isInputNum: false,
      minLength: 0,
      maxLength: 40,
      newOTP: '',
      failedLogins: 1
    };
  }

  handleOtpChange = otp => {
    this.setState({ otp });
  };

  handleCancel() {
    this.setState({ redirect: true });
    this.setState({ target: "/" });
  }

  handleChange = e => {
    let currVal = e.target.value;

    if (e.target.name === 'numInputs') {
      const { minLength, maxLength } = this.state;

      if (currVal < minLength || currVal > maxLength) {
        currVal = 4;
      }
    }
    this.setState({ [e.target.name]: currVal });
  };

  clearOtp = () => {
    this.setState({ otp: '' });
  };

  handleCheck = e => {
    const { name } = e.target;
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  verifyOTP(otp) {
    return new Promise((resolve, reject) => {
      var identifier = this.state.Id;
      var data = JSON.stringify({
        "identifier": identifier,
        "otp": otp
      });

      var config = {
        method: 'post',
        url: `${configFile.api.backendUrl}/verifyotp`,
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${configFile.api.xapikey}`
        },
        data: data
      };

      axios(config)
        .then((response) => {
          if (response === "false") {
            reject("invalid otp");
          } else {
            localStorage.setItem('usersession', response.data);
            resolve(true);
          }
        })
        .catch(reject)
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    var otp = this.state.otp;
    this.verifyOTP(otp).then((val) => {
        this.setState({ redirect: true, otp: otp, newOTP: otp });
    }).catch((error) => {
        this.setState({
          failedLogins: this.state.failedLogins + 1,
          showOTPError: "block",
          hasErrored: true,
        });
        if (this.state.failedLogins > 2) {
          this.setState({
            target: "/loginFields",
            redirect: true
          })
        }
      });
  };

  componentDidMount = () => {
    if ("Email" == this.state.Mode) {
      this.sendEmailMessage();
    }
    if ("Mobile" == this.state.Mode) {
      this.sendMessage();
    }
  }

  resendOTP = e => {
    e.preventDefault();
    if ("Email" == this.state.Mode) {
      this.sendEmailMessage();
    }
    if ("Mobile" == this.state.Mode) {
      this.sendMessage();
    }
  };

  sendMessage() {
    var data = JSON.stringify({
      "identifier": this.state.Id,
      "channel": "sms",
      "session": localStorage.getItem('usersession')
    });

    var config = {
      method: 'post',
      url: `${configFile.api.backendUrl}/sendotp`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": `${configFile.api.xapikey}`
      },
      data: data
    };

    axios(config)
      .then(function (response) { })
      .catch(function (error) {
        console.log(error);
      });
  }

  sendEmailMessage() {
    var data = JSON.stringify({
      "identifier": this.state.Id,
      "channel": "email",
      "session": localStorage.getItem('usersession')
    });

    var config = {
      method: 'post',
      url: `${configFile.api.backendUrl}/sendotp`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": `${configFile.api.xapikey}`
      },
      data: data
    };

    axios(config)
      .then(function (response) { })
      .catch(function (error) {
        console.log(error);
      });

  }
  render() {
    const {
      otp,
      numInputs,
      separator,
      isDisabled,
      hasErrored,
      isInputNum,
      minLength,
      maxLength,
      newOTP,
    } = this.state;

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.target,
        state: {
          id: this.state.Id,
          country: this.state.Country,
          region: this.state.Region,
          buildings: this.state.Buildings
        }
      }} />;
    }
    return (
      <div>
        <div className="text-center" style={{ margin: '30px 0px 0px 0px' }}>
          <p>
            <span style={{ fontSize: '20pt' }}>OTP has been sent to mobile or email address for registered Administrator</span>
          </p>
          <p>
            <span style={{ display: this.state.showOTPError, color: 'red' }}>Incorrect OTP captured, please click resend and try again</span>
          </p>
          <br />
          <p /><h3>Capture OTP</h3>
          <form onSubmit={this.handleSubmit}>
            <div className="containerOTP">
              <div className="view">
                <div className="card">
                  <OtpInput
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "5px",
                      fontSize: "1rem",
                      borderRadius: 4,
                      border: "2px solid rgba(0,0,0,0.3)",
                    }}
                    numInputs={numInputs}
                    isDisabled={isDisabled}
                    hasErrored={hasErrored}
                    errorStyle="error"
                    onChange={this.handleOtpChange}
                    separator={<span>{separator}</span>}
                    isInputNum={isInputNum}
                    shouldAutoFocus
                    value={otp}
                  />
                  <div >
                    <button
                      style={{ margin: '10px 0px 0px 0px' }}
                      className="btn btn-secondary"
                      disabled={otp.length < numInputs}>
                      Submit
                    </button>
                    <button
                      style={{ margin: '10px 0px 0px 10px' }}
                      className="btn btn-danger"
                      onClick={this.handleCancel}>
                      Cancel
                    </button>
                    <br />
                    <button style={{ margin: '10px 0px 70px 0px', textDecoration: 'underline', fontWeight: 'bold', color: '#4A4D4E' }} className="btn" onClick={this.resendOTP} >Resend OTP</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <br />

        <div className="main-footer">
          <div className="container">
            <img src={spacer}></img>
          </div>
          <div className="page-containerRight"></div>
        </div>

      </div>
    );
  }
}

export default otpPref;