import React, { Component } from 'react';
import "./footer.css";
import Cookies from 'universal-cookie';
import LogOut from "../Logout.PNG";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FormWithConstraints, FieldFeedbacks, Async, FieldFeedback } from 'react-form-with-constraints';
import ADMINICO from "../admin.png";
import PASSWICO from "../security.png";
import spacer from "../spacer.png";
import axios from "axios";
import { Redirect } from 'react-router';

const config = require('../config.json');
const cookies = new Cookies();

class Footer extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

  }

  state = {
    buildingName: cookies.get('DVBuilding'),
    redirect: false,
    showInfo: false,
    showLogonError: "none",
    username: "",
    password: ""
  };

  componentDidMount() {
    this.setState({ buildingName: cookies.get('DVBuilding') });
  }

  handleShow() {
    this.setState({ showLogonError: "none" });
    this.setState({ showInfo: true });
  }

  handleClose() {
    this.setState({ showLogonError: "none" });
    this.setState({ showInfo: false });
  }

  handleClick = () => {
    this.handleShow();

  };

  handleChange = e => {
    this.setState({ username: e.target.value })
    this.form.validateFields(e.target);
  }

  handleChangePassword = e => {
    this.setState({ password: e.target.value })
    this.form.validateFields(e.target);
  }

  fetchAdmins = async () => {
    try {


      const params = {
        "id": this.state.username.toLowerCase(),
        "password": this.state.password
      };

      const res = await axios.post(`${config.api.backendUrl}/adminuser`, params, {
        "headers": {
          "x-api-key": `${config.api.xapikey}`
        }
      });

      // console.log(res.status);


      //  const res = await axios.get(`${config.api.invokeUrl}/adminusers/` + this.state.username.toLowerCase() );

      this.setState({ admins: res.data }, function () {

      });

      if (this.state.admins.length == 0) {
        this.setState({ showLogonError: "block" });
      } else {
        if (res.status == "208") {
          cookies.set('DVBuilding', '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVHostPhoto", '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVHostName", '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVHostMail", '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVHostMobile", '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVvisitkey", '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVvisitdate", '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVvisitorType", '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVvisitreason", '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVvisitVisitors", '', { path: '/', secure: true, sameSite: "lax" });
          cookies.set("DVvisitDuration", '', { path: '/', secure: true, sameSite: "lax" });
          this.setState({ redirect: true });
        } else {
          this.setState({ showLogonError: "block" });
        }

      }

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
    }
  }

  adminSubmit = e => {
    e.preventDefault();
    this.setState({ showLogonError: "none" });
    this.form.validateFields();

    if (!this.form.isValid()) {
      // console.log('form is invalid: do not submit');
    } else {
      // console.log('form is valid: submit');
      this.fetchAdmins();
    }
  }

  render() {
    var logoutButton;
    if ((this.state.buildingName == '') || (this.state.buildingName == ' ')) {
      logoutButton = '';
    } else {
      // 
      logoutButton = <button onClick={this.handleClick} type="button" className="button"><img src={LogOut} alt='logout'></img></button>;
    }

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: "/",
      }} />;
    }
    return (
      <div>
        <div className="main-footer">
          <div className="container">
            <img src={spacer}></img>
          </div>
          <div className="page-containerRight">{logoutButton}</div>
        </div>
        <Modal
          show={this.state.showInfo}
          onHide={this.handleClose}
          dialogClassName="my-modal"
          backdrop="static"
          keyboard={false}>


          <Modal.Body>
            <p>
              <span className="popupMessageTitle">Please confirm administrator details to logout.</span>
            </p>

            <br />
            <FormWithConstraints
              ref={form => this.form = form}
              onSubmit={this.adminSubmit}
              noValidate>
              <div className="form-group">
                <img src={ADMINICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                <input
                  name="Username"
                  id="Username"
                  type="text"
                  placeholder="Username *"
                  className="form-control+matching-text-style"
                  required onChange={this.handleChange}
                  style={{ width: '210px' }}
                />
                <FieldFeedbacks for="Username">
                  <FieldFeedback when="*" />
                </FieldFeedbacks>
              </div>
              <div className="form-group">

                <img src={PASSWICO} style={{ width: '20px', padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px' }} />

                <input
                  name="Password"
                  id="Password"
                  type="password"
                  placeholder="Password *"
                  className="form-control+matching-text-style"
                  required onChange={this.handleChangePassword}
                  style={{ width: '210px' }}
                />
                <FieldFeedbacks for="Password">
                  <FieldFeedback when="*" />
                </FieldFeedbacks>
              </div>

            </FormWithConstraints>
            <div style={{ display: this.state.showLogonError, color: 'red' }}><p>Invalid deails captured please try again or email VisitorsAppSupport@vodacom.co.za</p></div>
            <div>

            </div>

            <Button variant="secondary" onClick={this.adminSubmit} style={{ width: '100px', margin: "0px 10px 0px 0px" }}>
              Confirm
            </Button>
            <Button variant="danger" onClick={this.handleClose} style={{ width: '100px' }} >
              Cancel
            </Button>

          </Modal.Body>

        </Modal>
      </div>
    );
  }
}

export default Footer;