import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import ADMINICO from "../admin.png";
import PASSWICO from "../security.png";
import ENTERPRICEPIC from "../enterprise.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';


class visitor extends Component {
    render() { 
        return (<div>
            <div className="text-center">
          <h4>No pre registration was found and we </h4>
          <h4>require your details to complete your log in</h4>
        </div>        
          <Container>         
          <Row>
            <Col></Col>
            <Col xs={6}>

            <div className="form-group">
              <img src={ADMINICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }} />
             
              <input
                name="FirstName"
                id="FirstName"
                type="text"
                placeholder = "First Name"
                className="form-control+matching-text-style"
                onChange={() => {}}
                style={{ width: '85%'}}
              />
            </div> 
            </Col>
            <Col></Col>
          </Row> 
          <Row>
            <Col></Col>
            <Col xs={6}>

            <div className="form-group">
              <img src={ADMINICO} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }} />
             
              <input
                name="Surname"
                id="Surname"
                type="text"
                placeholder = "Last Name"
                className="form-control+matching-text-style"
                onChange={() => {}}
                style={{ width: '85%'}}
              />
            </div> 
            </Col>
            <Col></Col>
          </Row>   
          <Row>
            <Col></Col>
            <Col xs={6}>

            <div className="form-group">
              <img src={ENTERPRICEPIC} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }} />
             
              <input
                name="Company"
                id="Company"
                type="text"
                placeholder = "Company Name"
                className="form-control+matching-text-style"
                onChange={() => {}}
                style={{ width: '85%'}}
              />
            </div> 
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col xs={6}>

            <div className="form-group">
            <div className="form-check">
                <label>
                    <input
                    type="radio"
                    name="react-tips"
                    value="option1"
                    checked={true}
                    className="form-check-input"
                    />
                    RSA ID
                </label>
            
                <label>
                    <input
                    type="radio"
                    name="react-tips"
                    value="option2"
                    className="form-check-input"
                    />
                    Passport Number
                </label>
            </div>
            </div> 
            </Col>
            <Col></Col>
          </Row>         
          <Row>
            <Col></Col>
            <Col xs={6}>

            <div className="form-group">
              <img src={ENTERPRICEPIC} style={{ width: '20px',padding: '0px 0px 10px 0px', margin: '0px 5px 0px 0px'  }} />
             
              <input
                name="Id"
                id="Id"
                type="text"
                placeholder = "RSA ID/Passport number"
                className="form-control+matching-text-style"
                onChange={() => {}}
                style={{ width: '85%'}}
              />
            </div> 
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col xs={6}>
            <p/>
            <Button href="visitorReject" variant="secondary" block>Next</Button>{' '}
            </Col>
            <Col></Col>
          </Row>
                
        </Container> 
                   
        </div>        
            );
    }
}
 
export default visitor;