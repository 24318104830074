import React, { Component } from 'react';
import headerLogo from "../logo-icon-top.png"
import headerImage1 from "../banner01.png"
import "./header.css";

class Headers extends Component {
    state = {  }
    render() { 
        return (<div>
            <img src={ headerImage1 } className="headerImg" alt='banner'/>
            <img src={ headerLogo } className="headerImgLogo" />
            <span className="headerTitleText">Digital Visitor</span>
        </div>);
    }
}
 
export default Headers;