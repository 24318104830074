import React, { Component } from 'react';
import axios from "axios";
import { Redirect } from 'react-router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Cookies from 'universal-cookie';
import Visitor from './visitor';
import Footer from './footer';
import { v4 as uuidv4 } from 'uuid'

const config = require('../config.json');
const cookies = new Cookies();

class registerSave extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      redirect: false,
      directTo: "/visitorwelcome",
      id: this.props.location.state.id,
      email: this.props.location.state.email,
      firstname: this.props.location.state.firstname,
      lastname: this.props.location.state.lastname,
      company: this.props.location.state.company,
      mobile: this.props.location.state.mobile,
      image: this.props.location.state.image,
      unqkey: this.props.location.state.unqkey,
      newVisitor: { 
        "id": "", 
        "firstname": "",
        "lastname": "",
        "company": "",
        "email": "",
        "laptop": "",
        "laptopserial": "",
        "mobile": "",
        "vehicle": "",
        "image": ""
      },
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getUTCFullYear()
    };
    this.handleRegister = this.handleRegister.bind(this);
    this.handleNo = this.handleNo.bind(this);
  }

  handleRegister() {
    this.setState({ redirect: true });
    this.setState({ directTo: "/otpRegister" });
    //this.setState({ directTo: "/register"});
  }

  handleNo() {
    this.setState({ redirect: true });

    // Frans - Added the save - 2022-07-15
    this.handleSaveVisitor();
    // this.setState({ directTo: "/additionalQuestions"});
    // this.setState({ directTo: "/additionalVisitor" });
  }

  handleSaveVisitor() {
    const guid = uuidv4();
    this.handleAddVisitsVisitor(
      this.state.id,
      this.state.firstname,
      this.state.lastname,
      guid,
      this.state.email,
      this.state.company,
      this.state.mobile,
      this.state.image);

    this.setState({ directTo: "/additionalVisitor" });
    this.setState({ redirect: true });
  }

  handleAddVisitsVisitor = async (id,firstname,lastname,guid,email,company,mobile,image) => {
    const curTime = new Date().toLocaleString();

    let currentTimeInMilliseconds = " ";
    currentTimeInMilliseconds = Date.now().toString(); // unix timestamp in milliseconds 

    // add call to AWS API Gateway add visitor endpoint here
    try {
        const params = {
          "newid": this.state.currentYear.toString() + this.state.currentMonth.toString(),
          "timestamps": currentTimeInMilliseconds,
          "id": guid,
          "visitkey": cookies.get('DVvisitkey'),
          "visitorid": id,
          "visitorname": firstname,
          "visitorlastname": lastname,
          "visitormobile": mobile,
          "visitoremail": email,
          "visitorcompany": company,
          "visitortype": cookies.get("DVvisitorType"),
          "country": cookies.get("DVCountry"),
          "building": cookies.get("DVBuilding"),
          "visitdate": cookies.get("DVvisitdate"),
          "hostname": cookies.get("DVHostName"),
          "visitreason": cookies.get("DVvisitreason"),
          "visitorimage": image,
          "visittime": cookies.get("DVvisitDuration"),
          username: localStorage.getItem('username'),
          usid: localStorage.getItem('usersession')
        };

        await axios.post(`${config.api.backendUrl}/visitsvisitor`, params,{"headers": {
          "x-api-key": `${config.api.xapikey}`
        }});

    }catch (err){
        console.log(`An error has occurred: ${err}`);
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.directTo,
        state: {
          id: this.state.id,
          email: this.state.email,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          company: this.state.company,
          mobile: this.state.mobile,
          mode: "Mobile",
          image: this.state.image,
          unqkey: this.state.unqkey
        }
      }} />;
    }
    return (
      <div className="text-center">


        <h5 style={{ padding: '0px 10px 0px 10px', margin: '30px 0px 30px 0px' }} >Would you like to register your information for easier access with your ID number on your next visit to Vodacom?</h5>

        <br></br>

        <Container>
          <Row>
            <Col className="text-right"></Col>
            <Col xs={6}>

              <Button onClick={this.handleRegister} variant="danger">Yes</Button>{' '}
              <Button onClick={this.handleNo} variant="secondary">No</Button>{' '}

            </Col>
            <Col></Col>
          </Row>
        </Container>

        <Footer></Footer>
      </div>
    )
  }
}

export default registerSave;