import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OTPClient from 'otp-client';
import OtpInput from 'react-otp-input';
import Headers from './header';
import Footers from './footer';
import axios from 'axios';
import './otpFields.css';
import { Redirect } from 'react-router';
import Footer from './footer';


const configFile = require('../config.json');

class otpRegister extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      otp: '',
      redirect: false,
      id: this.props.location.state.id,
      email: this.props.location.state.email,
      firstname: this.props.location.state.firstname,
      lastname: this.props.location.state.lastname,
      company: this.props.location.state.company,
      mobile: this.props.location.state.mobile,
      Mode: this.props.location.state.mode,
      unqkey: this.props.location.state.unqkey,
      numInputs: 6,
      separator: '',
      isDisabled: false,
      hasErrored: false,
      isInputNum: false,
      minLength: 0,
      maxLength: 40,
      newOTP: '',
      pathname: "/register",
      showOTPError: "none"
    };

  }

  handleOtpChange = otp => {
    this.setState({ otp });
  };

  handleCancel() {
    this.setState({ redirect: true });
    this.setState({ pathname: "/registerSave" });
  }

  handleChange = e => {
    let currVal = e.target.value;

    if (e.target.name === 'numInputs') {
      const { minLength, maxLength } = this.state;

      if (currVal < minLength || currVal > maxLength) {
        currVal = 4;

        console.error(
          `Please enter a value between ${minLength} and ${maxLength}`
        );
      }
    }

    this.setState({ [e.target.name]: currVal });
  };

  clearOtp = () => {
    this.setState({ otp: '' });
  };

  handleCheck = e => {
    const { name } = e.target;
    this.setState(prevState => ({ [name]: !prevState[name] }));
  };

  handleSubmit = e => {
    e.preventDefault();
    var otp = this.state.otp;
    this.verifyOTP(otp).then((val) => {
      this.setState({ redirect: true });
    }).catch ((error) => {
      this.setState({ showOTPError: "block" });
      this.setState({ hasErrored: true });
    });
  };

  componentDidMount = () => {
    if ("Email" == this.state.Mode) {
      this.sendEmailMessage();
    }
    if ("Mobile" == this.state.Mode) {
      this.sendMessage();
    }
  }

  resendOTP = e => {
    e.preventDefault();
    if ("Email" == this.state.Mode) {
      this.sendEmailMessage();
    }
    if ("Mobile" == this.state.Mode) {
      this.sendMessage();
    }
  };

  verifyOTP(otp) {
    var identifier = this.state.unqkey;
    return new Promise((resolve, reject) => {
      var data = JSON.stringify({
        "identifier": identifier,
        "otp": otp,
        "session": localStorage.getItem('usersession')
      });

      var config = {
        method: 'post',
        url: `${configFile.api.backendUrl}/validatetempotp`,
        headers: {
          "Content-Type": "application/json",
          "x-api-key": `${configFile.api.xapikey}`
        },
        data: data
      };

      axios(config)
        .then((response) => {
          resolve(true);
        })
        .catch(reject)
    });
  }
  
  sendMessage() {
    var mobile = this.state.mobile;
    var identifier = this.state.unqkey;
    var data = JSON.stringify({
      "identifier": identifier,
      "session": localStorage.getItem('usersession')
    });

    var config = {
      method: 'post',
      url: `${configFile.api.backendUrl}/sendtempotp`,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": `${configFile.api.xapikey}`
      },
      data: data
    };

    axios(config)
      .then(function (response) { })
      .catch(function (error) {
        console.log(error);
      });
  }

  sendEmailMessage = () => {
    return;
  }

  render() {
    const {
      otp,
      numInputs,
      separator,
      isDisabled,
      hasErrored,
      isInputNum,
      minLength,
      maxLength,
      newOTP,
    } = this.state;

    if (this.state.redirect) {
      return <Redirect push to={{
        pathname: this.state.pathname,
        state: {
          id: this.state.id,
          email: this.state.email,
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          company: this.state.company,
          mobile: this.state.mobile
        }
      }} />;
    }
    return (
      <div>
        <div className="text-center" style={{ margin: '30px 0px 0px 0px' }}>
          <p>
            <span style={{ fontSize: '20pt' }}>OTP has been sent to {this.state.mobile} </span>
          </p>
          <p>
            <span style={{ display: this.state.showOTPError, color: 'red' }}>Please click resend OTP and try again or click cancel</span>
          </p>
          <br />
          <p /><h3>Capture OTP</h3>
          <form onSubmit={this.handleSubmit}>
            <div className="containerOTP">
              <div className="view">
                <div className="card">
                  <OtpInput
                    inputStyle={{
                      width: "3rem",
                      height: "3rem",
                      margin: "5px",
                      fontSize: "1rem",
                      borderRadius: 4,
                      border: "2px solid rgba(0,0,0,0.3)",
                    }}
                    numInputs={numInputs}
                    isDisabled={isDisabled}
                    hasErrored={hasErrored}
                    errorStyle="error"
                    onChange={this.handleOtpChange}
                    separator={<span>{separator}</span>}
                    isInputNum={isInputNum}
                    shouldAutoFocus
                    value={otp}
                  />
                  <div >
                    <button
                      style={{ margin: '10px 0px 0px 0px' }}
                      className="btn btn-secondary"
                      disabled={otp.length < numInputs}>
                      Submit
                    </button>
                    <button
                      style={{ margin: '10px 0px 0px 10px' }}
                      className="btn btn-danger"
                      onClick={this.handleCancel}>
                      Cancel
                    </button>
                    <br />
                    <button style={{ margin: '10px 0px 70px 0px', textDecoration: 'underline', fontWeight: 'bold', color: '#4A4D4E' }} className="btn" onClick={this.resendOTP} >Resend OTP</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <br />

        <Footer></Footer>
      </div>
    );
  }
}

export default otpRegister;